import { useGetUmapQuery } from '@generated/UseGraphqlHooks';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { UmapGraph } from './UmapGraph';
import { UmapImagePreview } from './UmapImagePreview';

interface UmapMainProps {
  umapId: string;
  workspaceId: string;
}

export const UmapMain = ({ umapId, workspaceId }: UmapMainProps) => {
  const {
    data: umapQueryData,
    loading,
    refetch,
    startPolling,
    stopPolling,
  } = useGetUmapQuery({ variables: { workspaceId, umapId }, fetchPolicy: 'no-cache' });
  const umap = umapQueryData?.getUMAPs?.[0];

  useEffect(() => {
    console.log(umap);
    if (!umapQueryData?.getUMAPs?.[0]?.results) {
      startPolling(3000);
    } else {
      stopPolling();
    }
  }, [umapQueryData]);

  const [featureSize, setFeatureSize] = useState<'4' | '8' | '16' | '32' | '64'>('4');
  return (
    <Grid container spacing={4} sx={{ mt: 0, py: 7, height: '100%' }}>
      <Grid item md={umap?.status === 'success' ? 8 : 12} xs={12}>
        <UmapGraph
          umapId={umapId}
          workspaceId={workspaceId}
          umap={umap}
          loading={loading}
          refetch={refetch}
          featureSize={featureSize}
          setFeatureSize={setFeatureSize}
        />
      </Grid>
      {umap?.status === 'success' && (
        <Grid item md={4} sm={12} xs={12}>
          <UmapImagePreview workspaceId={workspaceId} featureSize={featureSize} />
        </Grid>
      )}
    </Grid>
  );
};
