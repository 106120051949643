import { PlusIcon } from '@assets/icons';
import { Button } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Stack } from '@mui/material';
import { NewAnnotationMapModal } from './NewAnnotationMapModal';

type AnnotationMapHeaderProps = {
  name: string;
  organizationId: string;
};

export const AnnotationMapHeader = ({ name, organizationId }: AnnotationMapHeaderProps) => {
  const { showModal: showNewAnnotationMapModal } = useModal({
    component: NewAnnotationMapModal,
    componentProps: { organizationId },
    modalProps: { title: 'New annotation map' },
  });

  return (
    <Stack spacing="2px">
      <Typography variant="caption2">{name}</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Annotation Maps</Typography>
        <Button startIcon={PlusIcon} onClick={showNewAnnotationMapModal}>
          New annotation map
        </Button>
      </Stack>
    </Stack>
  );
};
