import { ArrowLeftIcon, FolderIcon, FolderPlusIcon, LoadingIcon, VolumeIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { useGraphqlPagination } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import {
  GetVolumesQuery,
  useGetVolumeDataQuery,
  useGetVolumesQuery,
} from '@generated/UseGraphqlHooks';
import { Box, Divider, Stack, StackProps, useTheme } from '@mui/material';
import { GraphDataContext, ZoomContext } from '@subsets/workspaces';
import { ChannelNode } from '@subsets/workspaces/graphs/graph/types';
import { dropRight, first, map, size, split } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Virtuoso } from 'react-virtuoso';

interface VolumeViewRowProps {
  name: string;
  volumeName: string;
  type: string;
  volumeId: string;
  folderHistory: string[];
  hash?: string;
  thumbnailUrl?: string;
  setFolderHistory: Dispatch<SetStateAction<string[]>>;
}

interface VolumeRowProps {
  name: string;
  onBack: () => void;
  volumeId: string;
}

const VolumeViewRow = ({
  name,
  volumeName,
  type,
  volumeId,
  thumbnailUrl,
  folderHistory,
  hash,
  setFolderHistory,
}: VolumeViewRowProps) => {
  const { palette } = useTheme();
  const { createNode } = useContext(GraphDataContext);
  const { getCenterPosition } = useContext(ZoomContext);

  const onClick = async (key: string) => {
    if (type === 'folder') {
      setFolderHistory((prevState) => [...prevState, key]);
    } else if (type === 'file') {
      const folder =
        size(folderHistory) > 0 ? ['', ...folderHistory].join('/') : folderHistory.join('/');
      const newNodeData: ChannelNode = {
        color: '#246BB3',
        alias: first(split(key, '.')),
        name: 'VolumeFile',
        category: 'Object',
        subcategory: 'Generators',
        hash: hash || 'a7c19eb160150ee04d82af60c9332d104f0a7f89',
        tooltip: `${volumeName}:${folder}/${key}`,
        inputs: [
          {
            name: 'File',
            default: `${volumeId}:${folder}/${key}`,
            description: '',
            hidden: true,
          },
        ],
        outputs: [{ name: 'File', description: '' }],
      };
      await createNode(newNodeData, getCenterPosition());
    } else if (type === 'directory') {
      const folder =
        size(folderHistory) > 0 ? ['', ...folderHistory].join('/') : folderHistory.join('/');
      const directoryNodeName = `${volumeName}:${folder}/`;
      const newNodeData: ChannelNode = {
        color: '#246BB3',
        name: 'VolumeDirectory',
        alias: `${folder || volumeName}/`,
        category: 'Object',
        subcategory: 'Generators',
        hash: 'a7c19eb160150ee04d82af60c9332d104f0a7f89',
        tooltip: directoryNodeName,
        inputs: [
          {
            name: 'Directory',
            default: `${volumeId}:${folder}/`,
            description: '',
            hidden: true,
          },
        ],
        outputs: [{ name: 'Directory', description: '' }],
      };
      await createNode(newNodeData, getCenterPosition());
    }
  };

  return (
    <Stack
      direction="row"
      gap={4}
      alignItems="center"
      px={2}
      py={1.5}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          background: palette.table.row.hover,
        },
      }}
      onClick={() => onClick(name)}
    >
      <Stack width="24px" alignItems="center" justifyContent="center">
        {type === 'folder' && <FolderIcon size={20} />}
        {type === 'directory' && <FolderPlusIcon size={20} />}
      </Stack>
      <Typography variant="body2" handleOverFlow>
        {name}
      </Typography>
    </Stack>
  );
};

const VolumeView = ({ name, onBack, volumeId }: VolumeRowProps) => {
  const [folderHistory, setFolderHistory] = useState<string[]>([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setHasMore(true);
  }, [folderHistory]);

  const { data, loading, fetchMore } = useGetVolumeDataQuery({
    variables: {
      volumeId,
      dir: folderHistory.length > 0 ? folderHistory.join('/') : '/',
      limit: 50,
    },
  });

  const volumeData = useMemo(
    () =>
      data?.getVolumeData?.keys?.map((item) => ({
        ...item,
        type: item?.hash?.length > 0 ? 'file' : 'folder',
        name: item?.key?.split('/')[0],
      })) || [],
    [data],
  );

  const onFetchMore = async () => {
    if (!hasMore) {
      return;
    }
    if (volumeData?.length < 50) {
      setHasMore(false);
      return;
    }
    const { data: fetchMoreData } = await fetchMore({
      variables: { cursor: volumeData.length.toString(), limit: 50 },
    });
    if ((fetchMoreData?.getVolumeData?.keys as []).length < 50) {
      setHasMore(false);
    }
  };

  const handleBackArrowClick = () => {
    if (size(folderHistory) === 0) {
      onBack();
    } else {
      setFolderHistory((prevState) => dropRight(prevState));
    }
  };

  const item = useCallback(
    (index: number, row: (typeof volumeData)[0]) => (
      <VolumeViewRow
        key={`${row.key}${index}`}
        volumeId={volumeId}
        folderHistory={folderHistory}
        setFolderHistory={setFolderHistory}
        {...row}
        volumeName={name}
      />
    ),
    [fetchMore, folderHistory],
  );

  return (
    <Stack gap={4} pt={3} height="100%">
      <Stack direction="row" alignItems="center" gap={2}>
        <IconButton onClick={handleBackArrowClick} Icon={ArrowLeftIcon} size={20} />
        <VolumeIcon />
        <Typography variant="body2" handleOverFlow>
          {`${name} : ${folderHistory.join('/')}`}
        </Typography>
      </Stack>

      {!loading && size(volumeData) !== 0 && (
        <Stack gap={2} pl={8} flex={1}>
          <VolumeViewRow
            key="directory_node"
            volumeId={volumeId}
            folderHistory={folderHistory}
            setFolderHistory={setFolderHistory}
            volumeName={name}
            name="Add entire directory"
            type="directory"
          />
          <Divider />
          <Virtuoso
            totalCount={volumeData.length}
            overscan={100}
            endReached={onFetchMore}
            data={volumeData}
            itemContent={item}
          />
        </Stack>
      )}

      {loading && (
        <Stack direction="row" justifyContent="center">
          <LoadingIcon size={14} />
        </Stack>
      )}

      {size(volumeData) === 0 && !loading && (
        <Stack direction="row" justifyContent="center">
          <Typography variant="body2">No volume data available</Typography>
        </Stack>
      )}
    </Stack>
  );
};

const ChannelVolumeTreeItem = ({ name, ...props }: { name: string } & StackProps) => {
  const { palette } = useTheme();
  return (
    <Stack
      py={3}
      px={2}
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          background: palette.table.row.hover,
        },
      }}
      {...props}
    >
      <VolumeIcon />
      <Typography variant="body2" handleOverFlow disableTooltip>
        {name}
      </Typography>
    </Stack>
  );
};

export const ChannelVolumeTreeView = ({ workspaceId }: { workspaceId: string }) => {
  const [selectedVolume, setSelectedVolume] = useState<GetVolumesQuery['getVolumes'][0]>();
  const { data, loading, fetchMore, hasMore } = useGraphqlPagination(
    useGetVolumesQuery({ variables: { workspaceId, limit: 50 } }),
    'getVolumes',
    'volumeId',
  );
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: fetchMore,
  });

  if (loading) return <Box />;

  return (
    <Box height="100%">
      {!selectedVolume && (
        <Box ref={rootRef}>
          {map(data as GetVolumesQuery['getVolumes'], (volumeData, index) => (
            <ChannelVolumeTreeItem
              key={index}
              name={volumeData?.name}
              onClick={() => setSelectedVolume(volumeData)}
            />
          ))}
          {hasMore && (
            <Box
              ref={sentryRef}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              py={4}
            >
              <LoadingIcon size={14} />
            </Box>
          )}
        </Box>
      )}

      {selectedVolume && (
        <VolumeView
          name={selectedVolume.name}
          volumeId={selectedVolume.volumeId}
          onBack={() => setSelectedVolume(undefined)}
        />
      )}
    </Box>
  );
};
