import { XIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Typography } from '@components/typography';
import { Box, ModalProps as MUIModalProps, Modal as MuiModal } from '@mui/material';
import { globalHistory } from '@reach/router';
import { PropsWithChildren, useEffect } from 'react';

export type ModalProps = MUIModalProps & {
  showClose?: boolean;
  title?: string;
  onClose?: () => void;
};

export const Modal = ({
  children,
  showClose = true,
  sx,
  title,
  onClose,
  ...rest
}: PropsWithChildren<ModalProps>) => {
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' || action === 'POP') {
        onClose();
      }
    });
  }, []);
  return (
    <MuiModal onClose={onClose} closeAfterTransition {...rest}>
      <Box
        className="deckard-modal-container"
        sx={{ position: 'relative', p: 9, pt: title ? 7.25 : 9, minWidth: '450px', ...sx }}
      >
        {showClose && (
          <IconButton
            style={{ position: 'absolute', top: 8, right: 8 }}
            onClick={onClose}
            size={18}
            Icon={XIcon}
            aria-label="Close"
          />
        )}
        {title && (
          <Typography variant="h2" mb={6}>
            {title}
          </Typography>
        )}
        {children}
      </Box>
    </MuiModal>
  );
};
