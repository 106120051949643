import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import {
  useCreateManagedChannelMutation,
  useGetChannelsQuery,
  useGetOrganizationSettingsQuery,
} from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import * as yup from 'yup';
import { VolumesWithAccessDropdown } from './VolumesWithAccessDropdown';

interface NewChannelModalProps {
  onClose: () => void;
  organizationId: string;
}
interface SubmitType {
  name: string;
  description: string;
  timeout: number;
  volumes: string[];
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  description: yup.string(),
  timeout: yup
    .number()
    .positive('Timeout must be a positive number')
    .required('Timeout is required'),
});

export const NewChannelModal = ({ onClose, organizationId }: NewChannelModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [createManagedChannel] = useCreateManagedChannelMutation();
  const { refetch: refetchChannels } = useGetChannelsQuery({
    variables: {
      organizationId,
    },
  });
  const { refetch: channelsLimitsRefetch } = useGetOrganizationSettingsQuery({
    variables: { organizationId },
  });

  const handleFormSubmit = useAsyncNotification(
    'Successfully created the new channel',
    async ({ name, description, volumes, timeout }: SubmitType) => {
      const { data } = await createManagedChannel({
        variables: { name, organizationId, description, volumes, timeout: Number(timeout) },
      });
      if (data) {
        await refetchChannels();
        await channelsLimitsRefetch();
        onClose();
      }
    },
  );

  return (
    <Form
      enableReinitialize
      initialValues={{
        name: '',
        description: '',
        volumes: [],
        timeout: '',
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleFormSubmit}
    >
      {({ isValid, handleSubmit, dirty, isSubmitting }) => (
        <Stack>
          <FormTextInput name="name" label="Name" />
          <FormTextInput name="description" label="Description" multiline rows={3} />
          <VolumesWithAccessDropdown
            name="volumes"
            label="Volumes with access"
            organizationId={organizationId}
          />
          <FormTextInput name="timeout" type="number" label="Timeout (secs)" />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              loading={isSubmitting}
              disabled={!(isValid && dirty && !isSubmitting)}
              onClick={handleSubmit}
            >
              Create
            </AsyncButton>
            <Button fullWidth variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
