import { GraphqlPagination, useGraphqlPagination } from '@components/infiniteScroll';
import { Dataset, GetDatasetsQuery, useGetDatasetsQuery } from '@generated/UseGraphqlHooks';
import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type DatasetsProviderType = {
  datasets: Dataset[];
  loading: boolean;
  pagination: GraphqlPagination<Dataset>;
  selectedDataset: string;
  selectedDatasets: string[];
  refetchDatasets: VoidFunction;
  setSelectedDataset: Dispatch<string>;
  setSelectedDatasets: Dispatch<string[]>;
};

interface DatasetsProviderProps {
  workspaceId: string;
}

export const DatasetsContext = createContext<DatasetsProviderType>({} as DatasetsProviderType);
export const DatasetsProvider = ({
  children,
  workspaceId,
}: PropsWithChildren<DatasetsProviderProps>) => {
  const [selectedDataset, setSelectedDataset] = useState<string>();
  const [selectedDatasets, setSelectedDatasets] = useState<string[]>([]);

  const pagination = useGraphqlPagination<GetDatasetsQuery, Dataset>(
    useGetDatasetsQuery({
      variables: {
        workspaceId,
        limit: 50,
      },
    }),
    'getDatasets',
    'datasetId',
    { pollInterval: 5000 },
  );

  const datasets = pagination.data;

  const value = useMemo(
    () => ({
      datasets,
      loading: pagination.loading,
      pagination,
      selectedDataset,
      selectedDatasets,
      refetchDatasets: pagination.refetch,
      setSelectedDataset,
      setSelectedDatasets,
    }),
    [
      datasets,
      pagination.hasMore,
      pagination.loading,
      pagination.fetchMore,
      pagination.refetch,
      selectedDataset,
      selectedDatasets,
      setSelectedDataset,
      setSelectedDatasets,
    ],
  );

  return <DatasetsContext.Provider value={value}>{children}</DatasetsContext.Provider>;
};

export const useDatasets = () => useContext(DatasetsContext);
export const useDataset = (datasetId: string) => {
  const { datasets } = useDatasets();
  return datasets.find((dataset) => dataset.datasetId === datasetId);
};
