import { GridBox } from '@components/grid-box';
import { Image } from '@components/image';
import { useConfirmation, useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Graph, useDeleteGraphMutation } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { navigate } from 'gatsby';
import moment from 'moment';
import { memo } from 'react';
import { GraphsListViewPreviewMenuDownloadConfiguration } from './GraphsListViewPreviewMenuDownloadConfiguration';
import { GraphsListViewPreviewMenuDuplicateConfiguration } from './GraphsListViewPreviewMenuDuplicateConfiguration';
import { GraphsListViewPreviewMenuEditConfiguration } from './GraphsListViewPreviewMenuEditConfiguration';

interface GraphsGridViewPreviewProps {
  graph: Graph;
  refetch: () => void;
}

export const GraphsGridViewPreview = memo(({ graph, refetch }: GraphsGridViewPreviewProps) => {
  const [deleteGraph] = useDeleteGraphMutation({
    variables: { workspaceId: graph?.workspaceId, graphId: graph?.graphId },
  });

  const { useAsyncNotification } = useNotifications();
  const { showConfirmation } = useConfirmation({
    onAffirm: useAsyncNotification('Successfully deleted graph.', async () => {
      await deleteGraph();
      refetch();
    }),
    message: `Are you sure you want to delete ${`${graph?.name} ` || ''}graph?`,
  });
  const handleDelete = () => showConfirmation();

  const { showModal: showEditModal } = useModal({
    component: GraphsListViewPreviewMenuEditConfiguration,
    componentProps: { ...graph, refetch },
    modalProps: { title: `Edit ${graph?.name}` },
  });

  const { showModal: showDuplicateModal } = useModal({
    component: GraphsListViewPreviewMenuDuplicateConfiguration,
    componentProps: { ...graph, refetch },
    modalProps: { title: `Save ${graph?.name || 'graph'} copy as` },
  });

  const { showModal: showDownloadModal } = useModal({
    component: GraphsListViewPreviewMenuDownloadConfiguration,
    componentProps: { ...graph },
    modalProps: { title: `Download ${graph?.name}` },
  });

  return (
    <GridBox
      sx={{ height: '100%', width: '100%' }}
      title={graph?.name || '-'}
      description={
        graph?.updatedAt !== graph?.createdAt
          ? `Edited ${moment(graph?.updatedAt).fromNow()}`
          : `Created ${moment(graph?.createdAt).fromNow()}`
      }
      menuItems={[
        { label: 'Edit', onClick: showEditModal },
        { label: 'Duplicate', onClick: showDuplicateModal },
        { label: 'Download', onClick: showDownloadModal },
        { label: 'Delete', onClick: handleDelete },
      ]}
      content={<Image url={graph?.thumbnail} />}
      details={
        <Stack gap={2}>
          <Typography variant="caption2">Creator</Typography>
          <Typography variant="caption1">{graph?.createdBy || '-'}</Typography>
          <Typography variant="caption2">Description</Typography>
          <Typography variant="caption1" color="white">
            {graph?.description || '-'}
          </Typography>
        </Stack>
      }
      onClick={() => navigate(`/workspaces/${graph?.workspaceId}/graphs/${graph?.graphId}`)}
    />
  );
});
