import {
  FormControlLabel,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  useTheme,
} from '@mui/material';

export type RadioProps = MuiRadioProps & { label?: string };

export const Radio = ({ disabled, label, ...props }: RadioProps) => {
  const { palette } = useTheme();
  return (
    <FormControlLabel
      control={
        <MuiRadio
          disableRipple
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className="radio-icon-unchecked"
            >
              <circle cx="8" cy="8" r="6" fill={palette.radio.fill.unchecked} strokeWidth="4" />
            </svg>
          }
          checkedIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className="radio-icon-checked"
            >
              <circle cx="8" cy="8" r="6" fill={palette.radio.fill.checked} strokeWidth="4" />
            </svg>
          }
          disabled={disabled}
          {...props}
        />
      }
      sx={{ marginRight: label ? 4 : 0 }}
      label={label}
    />
  );
};
