import { XIcon } from '@assets/icons';
import { Chip, ChipProps, useTheme } from '@mui/material';
import { MouseEvent, ReactNode } from 'react';

type TagProps = ChipProps & {
  label?: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onDelete?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const Tag = ({ label, onClick, onDelete, ...rest }: TagProps) => {
  return (
    <Chip
      {...rest}
      label={label}
      onClick={onClick}
      onDelete={(e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (onDelete) {
          onDelete(e);
        }
      }}
      deleteIcon={
        onDelete ? <XIcon size={16} onMouseDown={(e) => e.stopPropagation()} /> : undefined
      }
      variant="outlined"
    />
  );
};
