import { Button } from '@components/button';
import { Group } from '@components/layout';
import { useModal } from '@components/modal';
import { Grid, useTheme } from '@mui/material';
import { OrganizationsMembersInviteMemberModal } from '@subsets/organizations';
import { navigate } from 'gatsby';
import { LandingPageOrganizationDetailsJobs } from './LandingPageOrganizationDetailsJobs';
import { LandingPageWorkspacesDetailsResources } from './LandingPageOrganizationDetailsResources';

type LandingPageWorkspacesDetailsProps = {
  organizationId: string;
};

export const LandingPageOrganizationDetails = ({
  organizationId,
}: LandingPageWorkspacesDetailsProps) => {
  const { palette } = useTheme();

  const navigateToOrganizationSettings: () => Promise<void> = async () => {
    await navigate(`/organizations/${organizationId}/settings`);
  };

  const { showModal: openInviteMemberModal } = useModal({
    component: OrganizationsMembersInviteMemberModal,
    componentProps: { organizationId },
  });

  return (
    <Grid
      item
      xs={3.5}
      borderLeft={`1px solid ${palette.mode === 'dark' ? palette.grey[700] : palette.grey[200]}`}
      height="100%"
      sx={{ display: 'flex', flexDirection: 'column', pt: 10, pl: 10, pr: 4, gap: 9 }}
    >
      <Group gap={3}>
        <Button fullWidth onClick={navigateToOrganizationSettings}>
          Settings
        </Button>
        <Button fullWidth onClick={openInviteMemberModal}>
          Invite
        </Button>
      </Group>
      <LandingPageWorkspacesDetailsResources organizationId={organizationId} />
      <LandingPageOrganizationDetailsJobs organizationId={organizationId} />
    </Grid>
  );
};
