import { InfoIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { OptionsMenu, OptionsMenuItem } from '@components/menu';
import { Typography } from '@components/typography';
import { Box, Card, CardContent, CardHeader, CardProps, Slide, useTheme } from '@mui/material';
import { MouseEventHandler, ReactElement, ReactNode, useRef } from 'react';
import { useToggle } from 'react-use';

export const GridBox = ({
  title,
  description,
  menuItems,
  content,
  details,
  onClick,
  disabled = false,
  sx,
  ...props
}: Omit<CardProps, 'content'> & {
  content?: ReactNode;
  description?: string;
  menuItems?: OptionsMenuItem[];
  details?: ReactElement;
  disabled?: boolean;
}) => {
  const { palette } = useTheme();
  const [showDetails, toggleShowDetails] = useToggle(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const handleDetailsToggle: MouseEventHandler = (event) => {
    event.stopPropagation();
    toggleShowDetails();
  };
  return (
    <Card
      {...props}
      sx={{
        ...sx,
        position: 'relative',
        '&:hover': onClick
          ? {
              border: `1px solid ${palette.grey[500]}`,
              cursor: 'pointer',
            }
          : {},
      }}
      ref={cardRef}
      onClick={onClick}
    >
      <Slide
        in={details && showDetails}
        direction="up"
        container={cardRef.current}
        children={
          <Box
            sx={{
              position: 'absolute',
              padding: 4,
              zIndex: 1,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: palette.mode === 'dark' ? palette.grey[900] : palette.grey[900],
            }}
            onClick={handleDetailsToggle}
          >
            {details}
          </Box>
        }
      />
      {(title || description) && (
        <CardHeader
          disableTypography
          sx={{ '& .MuiCardHeader-content': { overflow: 'hidden' } }}
          title={
            <Typography variant="body1" handleOverFlow>
              {title}
            </Typography>
          }
          subheader={
            <Typography variant="caption2" component="p" handleOverFlow>
              {description}
            </Typography>
          }
          action={menuItems ? <OptionsMenu items={menuItems} /> : null}
        />
      )}
      <CardContent sx={{ position: 'relative' }}>
        <>
          {content}
          {details && (
            <IconButton
              Icon={InfoIcon}
              size={20}
              aria-label="details"
              onClick={handleDetailsToggle}
              sx={{
                position: 'absolute',
                right: 4,
                bottom: 4,
                borderRadius: '20px',
                backgroundColor: palette.background.default,
                border: '1px solid transparent',
                '&:hover': {
                  border: `1px solid ${palette.grey[500]}`,
                },
              }}
            />
          )}
        </>
      </CardContent>
    </Card>
  );
};
