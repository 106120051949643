import { LoadingIcon } from '@assets/icons';
import { InfiniteScrollTableComponents } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import { Dataset } from '@generated/UseGraphqlHooks';
import { Box, Stack, TableRow, useTheme } from '@mui/material';
import { useDatasets } from '@subsets/workspaces';
import { TableVirtuoso } from 'react-virtuoso';
import { DatasetsTableHeader } from './DatasetsTableHeader';
import { DatasetsTableRow } from './DatasetsTableRow';

export const DatasetsTable = () => {
  const {
    datasets,
    loading,
    pagination,
    selectedDataset,
    selectedDatasets,
    setSelectedDataset,
    setSelectedDatasets,
  } = useDatasets();

  const { palette } = useTheme();

  if (loading) {
    return (
      <Stack
        bgcolor={palette.background.paper}
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <LoadingIcon size={16} />
      </Stack>
    );
  }

  if (datasets.length === 0) {
    return (
      <Stack
        bgcolor={palette.background.paper}
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Typography color={palette.text.paper}>No datasets exist in this workspace.</Typography>
      </Stack>
    );
  }

  const handleTableRowSelection = (id: string) => {
    setSelectedDataset(id);
  };

  const tableRow = (props: { item: Dataset }) => (
    <TableRow
      {...props}
      onClick={() => {
        handleTableRowSelection(props.item.datasetId);
      }}
      sx={{
        backgroundColor: props.item.datasetId === selectedDataset && palette.table.row.selected,
      }}
    />
  );

  const item = (_index, dataset: Dataset) => (
    <DatasetsTableRow dataset={dataset} context={{ selectedDatasets, setSelectedDatasets }} />
  );

  return (
    <Stack bgcolor={palette.background.paper} height="100%" p={4} pb={0}>
      {datasets?.length > 0 && (
        <Box data-test-id="virtuoso-item-list" sx={{ flex: 1, height: 0 }}>
          <TableVirtuoso
            data={datasets}
            endReached={pagination.fetchMore}
            components={{
              ...(InfiniteScrollTableComponents as any),
              TableRow: tableRow,
            }}
            fixedHeaderContent={DatasetsTableHeader}
            itemContent={item}
            increaseViewportBy={400}
          />
        </Box>
      )}
    </Stack>
  );
};
