import { GridIcon, OutlineIcon } from '@assets/icons';
import { DeckardIcon } from '@assets/types';
import { Box, useTheme } from '@mui/material';
import { MouseEventHandler } from 'react';

export type TableView = 'grid' | 'list';

const TableViewButtonPart = ({
  icon: Icon,
  right,
  selected,
  onClick,
}: {
  icon: DeckardIcon;
  right?: boolean;
  selected: boolean;
  onClick: MouseEventHandler;
}) => {
  const { palette } = useTheme();
  return (
    <Box
      py={1.35}
      px={2.5}
      display="flex"
      alignItems="center"
      onClick={onClick}
      style={{ cursor: 'pointer', outline: 'none' }}
      border="1px solid"
      borderColor={
        selected
          ? palette.table.modeButton.border.selected
          : palette.table.modeButton.border.default
      }
      sx={{
        borderRadius: right ? '0 3px 3px 0' : '3px 0 0 3px',
        backgroundColor: palette.background.paper,
        borderRightWidth: (selected && !right) || right ? 1 : 0,
        borderLeftWidth: (selected && right) || !right ? 1 : 0,

        '&:hover': {
          backgroundColor: palette.table.modeButton.hover,
        },
      }}
    >
      <Icon
        color={
          selected ? palette.table.modeButton.icon.selected : palette.table.modeButton.icon.default
        }
      />
    </Box>
  );
};

export type TableViewButtonProps = {
  mode: TableView;
  onChange: (newValue: TableView) => void;
};

export const TableViewButton = ({ mode, onChange }: TableViewButtonProps) => {
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" pl="31px">
      <TableViewButtonPart
        icon={GridIcon}
        selected={mode === 'grid'}
        onClick={() => onChange('grid')}
      />
      <TableViewButtonPart
        icon={OutlineIcon}
        right
        selected={mode === 'list'}
        onClick={() => onChange('list')}
      />
    </Box>
  );
};
