import { Button } from '@components/button';
import { Form, FormSelect, FormTextInput } from '@components/form';
import {
  useAddMemberMutation,
  useGetInvitationsQuery,
  useGetMembersQuery,
  useGetOrganizationSettingsQuery,
} from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { MenuItem, Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { find, toLower } from 'lodash';
import * as Yup from 'yup';

const checkIfUserIsMemberOfOrganization = (members) => (email: string) =>
  !find(members, { email: toLower(email) });
const checkIfUserInvitedToOrganization = (invitations) => (email: string) =>
  !find(invitations, {
    email: toLower(email),
  });

interface OrganizationsMembersInviteMemberModalProps {
  onClose?: () => void;
  organizationId: string;
  organizationRole?: string;
}

export const OrganizationsMembersInviteMemberModal = ({
  onClose,
  organizationId,
  organizationRole,
}: OrganizationsMembersInviteMemberModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [addMemberMutation] = useAddMemberMutation();

  const { data: organizationMembers, refetch: organizationMembersRefetch } = useGetMembersQuery({
    variables: {
      organizationId,
    },
  });
  const { data: organizationInvitations, refetch: organizationInvitationsRefetch } =
    useGetInvitationsQuery({
      variables: { organizationId },
    });
  const { refetch: organizationSettingsRefetch } = useGetOrganizationSettingsQuery({
    variables: { organizationId },
  });

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Please provide a valid email address.')
      .test(
        'duplicated',
        'This user is already a member of this organization.',
        checkIfUserIsMemberOfOrganization(organizationMembers?.getMembers),
      )
      .test(
        'duplicated',
        'This user is already invited this organization.',
        checkIfUserInvitedToOrganization(organizationInvitations?.getInvitations),
      )
      .required('Please provide a valid email address.'),
    role: Yup.string().required('Required'),
  });

  const handleSubmit = useAsyncNotification(
    'Organization invitation sent.',
    async ({ email, role }: { email: string; role: string }) => {
      onClose();
      await addMemberMutation({
        variables: {
          organizationId,
          email,
          role: role.toLowerCase(),
        },
      });
      await delay(1000);
      await organizationSettingsRefetch();
      await organizationMembersRefetch();
      await organizationInvitationsRefetch();
    },
  );

  return (
    <Form
      enableReinitialize
      initialValues={{ email: '', role: 'member' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Stack>
          <FormTextInput name="email" label="Email address" />
          <FormSelect name="role" label="Role" placeholder="Select a Role">
            {organizationRole === 'admin' && <MenuItem value="admin">Admin</MenuItem>}
            <MenuItem value="member">Member</MenuItem>
          </FormSelect>
          <Stack gap={4}>
            <Button fullWidth type="submit" disabled={!(dirty && isValid) || isSubmitting}>
              Invite
            </Button>
            <Button fullWidth variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
