import { Backdrop, Box, Drawer as MUIDrawer, DrawerProps as MuiDrawerProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type DrawerProps = MuiDrawerProps & {
  open: boolean;
  onClose?: () => void;
};

export const Drawer = ({ open, onClose, ...drawerProps }: PropsWithChildren<DrawerProps>) => {
  return (
    <Box overflow="hidden">
      <Backdrop open={open} onClick={onClose} style={{ zIndex: 1199, position: 'absolute' }} />
      <MUIDrawer
        open={open}
        anchor="left"
        onClose={onClose}
        variant="persistent"
        PaperProps={{
          style: {
            position: 'absolute',
            border: 'none',
            overflow: 'visible',
            visibility: 'visible',
          },
        }}
        ModalProps={{
          disableEnforceFocus: true,
          style: { position: 'absolute' },
        }}
        {...drawerProps}
      />
    </Box>
  );
};
