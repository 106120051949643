import { DeckardIcon } from '@assets/types';
import { Typography } from '@components/typography';
import { InputBase, InputBaseProps, Stack, useTheme } from '@mui/material';

export type TextInputProps = InputBaseProps & {
  label?: string;
  maintainGutters?: boolean;
  startIcon?: DeckardIcon;
  endIcon?: DeckardIcon;
  small?: boolean;
  min?: number;
  max?: number;
  containerProps?: Record<string, unknown>;
  onClickEndIcon?: () => void;
};

export const TextInput = ({
  disabled,
  error,
  label,
  name,
  startIcon: StartIcon,
  endIcon: EndIcon,
  small = false,
  sx,
  containerProps,
  onClickEndIcon,
  min,
  max,
  ...props
}: TextInputProps) => {
  const { palette, typography } = useTheme();
  return (
    <Stack width="100%" {...containerProps}>
      {label && (
        <Typography
          className="deckard-text-input-label"
          component="label"
          htmlFor={name}
          mb={1}
          variant="caption2"
          color={
            disabled
              ? palette.input.label.disabled
              : error
                ? palette.input.label.error
                : palette.input.label.default
          }
        >
          {label}
        </Typography>
      )}
      <InputBase
        fullWidth
        error={Boolean(error)}
        disabled={disabled}
        id={name}
        startAdornment={StartIcon && <StartIcon size={16} />}
        endAdornment={
          EndIcon && (
            <EndIcon
              size={16}
              style={{ cursor: disabled ? 'default' : 'pointer' }}
              onClick={() => {
                if (onClickEndIcon) {
                  onClickEndIcon();
                }
              }}
            />
          )
        }
        sx={{
          ...(small ? { ...typography.caption2, height: '24px', minHeight: '24px' } : {}),
          ...sx,
        }}
        inputProps={{ min, max }}
        {...props}
      />
    </Stack>
  );
};
