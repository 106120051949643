import { AsyncButton, Button } from '@components/button';
import { Form, FormSelect, FormTextInput } from '@components/form';
import { Typography } from '@components/typography';
import { useCreateGanDatasetMutation, useGetGanModelsQuery } from '@generated/UseGraphqlHooks';
import { MenuItem, Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { FormikProps } from 'formik';
import { find, includes, isEmpty, values as lodashValues, map, size, sortBy } from 'lodash';
import * as yup from 'yup';

const formInitialValues = {
  name: '',
  modelName: '',
};
const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  modelName: yup.string().trim().required('Model name is required'),
  description: yup.string(),
});

interface DatasetsDetailsGanDatasetsCreateModalProps {
  datasetId: string;
  workspaceId: string;
  onClose: VoidFunction;
  refetch: VoidFunction;
  refetchDatasets: VoidFunction;
}

export const DatasetsDetailsGanDatasetsCreateModal = ({
  onClose,
  datasetId,
  workspaceId,
  refetch,
  refetchDatasets,
}: DatasetsDetailsGanDatasetsCreateModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const { data, loading: ganModelsLoading } = useGetGanModelsQuery({ variables: { workspaceId } });
  const [createGanDataset] = useCreateGanDatasetMutation();

  const ganModels = data?.getGANModels || [];
  const sortedGanModels = sortBy(ganModels, ({ name }) => name);
  const ganModelsModelIds = map(sortedGanModels, 'modelId') || [];

  const handleCreateGanDataset = useAsyncNotification(
    'Successfully created GAN Dataset.',
    async ({
      modelName,
      name,
      description,
    }: {
      modelName: string;
      name: string;
      description: string;
    }) => {
      await createGanDataset({
        variables: { datasetId, modelId: modelName, workspaceId, name, description },
      });
      if (refetch) {
        refetch();
      }
      if (refetchDatasets) {
        refetchDatasets();
      }
      onClose();
    },
  );

  if (ganModelsLoading) return <Typography variant="body1">Loading...</Typography>;

  return (
    <Form
      validateOnBlur={false}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleCreateGanDataset}
    >
      {(properties: FormikProps<{ name: string }>) => {
        const { handleSubmit, errors, isValid, dirty, isSubmitting, isValidating } = properties;
        const checkIfAllValuesAreFilled = includes(
          map(lodashValues(isValidating) || [], (value: string) => size(value)),
          0,
        );
        const disabled =
          !isEmpty(errors) ||
          isSubmitting ||
          size(sortedGanModels) === 0 ||
          !(isValid && dirty) ||
          checkIfAllValuesAreFilled;

        return (
          <Stack>
            <FormTextInput label="Name" name="name" />
            <FormSelect label="Model" name="modelName" placeholder="select">
              {sortedGanModels.length > 0 ? (
                ganModelsModelIds.map((option) => (
                  <MenuItem value={option}>
                    {find(sortedGanModels, { modelId: option }).name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">
                  Your organization has no GAN Models. Contact your admin.
                </MenuItem>
              )}
            </FormSelect>
            <FormTextInput label="Description" name="description" multiline rows={3} />
            <Stack gap={4}>
              <AsyncButton
                fullWidth
                type="submit"
                disabled={disabled}
                data-cy="GAN-Datasets-Create-Dataset-Create-Button"
              >
                Create
              </AsyncButton>
              <Button
                fullWidth
                variant="secondary"
                onClick={onClose}
                data-cy="GAN-Datasets-Create-Dataset-Cancel-Button"
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        );
      }}
    </Form>
  );
};
