import {
  ChannelDetailContext,
  GraphDataContext,
  PositionContext,
  SelectionContext,
} from '@subsets/workspaces';
import { ChannelNode, Node as NodeType } from '@subsets/workspaces/graphs/graph/types';
import { drag, select } from 'd3';
import { useObservableState } from 'observable-hooks';
import { memo, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Node } from './Node';
import { NodeState } from './types';

type DraggableNodeProps = NodeType & { disabled: boolean; index: number };
export const DraggableNode = memo((props: DraggableNodeProps) => {
  const { name, location, hash, nodeClass, disabled } = props;
  const { registerNodeReference, getNodePosition } = useContext(PositionContext);
  const ref = useRef<SVGSVGElement>();
  const container = useRef<HTMLDivElement>();
  const { links, nodes, updateNodeLocation, createLink } = useContext(GraphDataContext);
  const { isSelected, selectNode, selectedNodeList, selectedOutputPort } =
    useContext(SelectionContext);

  const selectedNodes = useObservableState<Set<string>>(selectedNodeList);
  const { data$ } = useContext(ChannelDetailContext);
  const channelNodes: ChannelNode[] = useObservableState<ChannelNode[]>(data$);
  const channelNode = channelNodes?.find((findNode) => findNode.name === nodeClass);
  const isNodeOutdated =
    Boolean(channelNode?.hash) &&
    Boolean(channelNode?.hash ? hash !== channelNode.hash : hash !== channelNode?.schemaHash);
  let position$ = getNodePosition(name);
  const linksMappedByNodeAndPortId = links.map(
    (link: { from: { nodeId: string; portId: string }; to: { nodeId: string; portId: string } }) =>
      `${link?.from?.nodeId}-${link?.from?.portId}-${link?.to?.nodeId}-${link?.to?.portId}`,
  );

  useEffect(() => {
    position$ = getNodePosition(name);
    position$.next([location?.x, location?.y]);
  }, [location?.x, location?.y]);

  useEffect(() => {
    let moved = false;
    const onDrag = (event: DragEvent & { dx: number; dy: number }) => {
      moved = true;
      let isMultiple = selectedNodeList.getValue().size > 1;
      if (!isSelected(name)) {
        selectNode(name, event);
        isMultiple = false;
      }
      selectedOutputPort.next(undefined);
      const list = isMultiple ? selectedNodeList.getValue() : new Set([name]);
      const { dx, dy } = event;
      Array.from(list).map((id) => {
        position$ = getNodePosition(id);
        const [currentX, currentY] = position$.getValue();
        position$.next([currentX + dx, currentY + dy]);
        return id;
      });
    };
    const onDragEnd = () => {
      if (moved) {
        const isMultiple = selectedNodeList.getValue().size > 1;
        if (isMultiple) {
          Array.from(selectedNodeList.getValue()).forEach((selectedNodeId, index) => {
            position$ = getNodePosition(selectedNodeId);
            updateNodeLocation(
              selectedNodeId,
              { x: position$.value[0], y: position$.value[1] },
              index === selectedNodeList.getValue().size - 1,
            );
          });
        } else {
          updateNodeLocation(name, { x: position$.value[0], y: position$.value[1] });
        }
      } else if (!isSelected(name)) {
        selectNode(name, {} as MouseEvent);
      }
    };
    select(ref.current).call(
      drag()
        .on('drag', !disabled ? onDrag : () => {})
        .on('end', !disabled ? onDragEnd : () => {}),
    );
    const sub = position$.subscribe(([currentX, currentY]) => {
      select(ref.current).attr('transform', `translate(${currentX}, ${currentY})`);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [ref, position$, isSelected, selectNode, selectedNodeList]);

  useEffect(() => {
    registerNodeReference(name, container);
  }, [name, container, registerNodeReference]);

  const state = useMemo(() => {
    const isSelectedAlone = selectedNodes.size === 1 && isSelected(name);
    if (isSelectedAlone) {
      return 'focus';
    }
    if (isSelected(name)) {
      return 'active';
    }
    if (nodeClass === 'VolumeFile' || nodeClass === 'VolumeDirectory') {
      return 'default';
    }
    if (isNodeOutdated) {
      return 'outdated';
    }
    return 'default';
  }, [selectedNodes]);

  const onOutputPortSelect = useCallback(
    (portId: string) => {
      if (disabled) {
        return;
      }
      selectedOutputPort.next({ portId, nodeId: name });
    },
    [name, selectedOutputPort],
  );

  const onInputPortSelect = useCallback(
    async (portId: string) => {
      const from = selectedOutputPort.getValue();
      if (!from) {
        return;
      }
      const isPortOfSameNode = from?.nodeId === name;
      if (isPortOfSameNode) {
        return;
      }
      const isLinkAlreadyCreatedBetweenNodes = linksMappedByNodeAndPortId.includes(
        `${from?.nodeId}-${from?.portId}-${name}-${portId}`,
      );
      selectedOutputPort.next(undefined);
      if (isLinkAlreadyCreatedBetweenNodes) {
        return;
      }
      await createLink({
        from,
        to: { portId, nodeId: name },
      });
    },
    [selectedOutputPort, createLink, links, nodes],
  );

  return (
    <Node
      ref={ref}
      {...props}
      container={container}
      state={state as NodeState}
      onOutputPortSelect={onOutputPortSelect}
      onInputPortSelect={onInputPortSelect}
    />
  );
});
