import { Banner } from '@components/banner';
import { Group } from '@components/layout';
import { Box, Container, Stack } from '@mui/material';
import { useEffect } from 'react';
import { DatasetsActions, DatasetsDetails, DatasetsTable, useDatasets } from './datasets';

interface LibrariesDatasetsProps {
  workspaceId: string;
  datasetId?: string;
}

export const LibrariesDatasets = (params: LibrariesDatasetsProps) => {
  const { datasetId } = params;
  const { setSelectedDataset } = useDatasets();
  useEffect(() => {
    if (datasetId) {
      setSelectedDataset(datasetId);
    }
  }, [datasetId]);

  return (
    <Stack height="100%" gap={2} sx={{ overflow: 'hidden' }}>
      <Banner title="Datasets" />
      <Container
        maxWidth="lg"
        disableGutters
        sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      >
        <Group height="100%">
          <Box flex={0}>
            <DatasetsActions {...params} />
          </Box>
          <Box flex={1}>
            <DatasetsTable />
          </Box>
          <Box width="35%">
            <DatasetsDetails {...params} />
          </Box>
        </Group>
      </Container>
    </Stack>
  );
};
