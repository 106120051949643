import { AsyncButton, Button } from '@components/button';
import { Form, FormFileUpload, FormTextInput } from '@components/form';
import { useFileUpload } from '@fileUpload/FileUpload';
import {
  useUploadDatasetV2FinalizerMutation,
  useUploadDatasetV2Mutation,
} from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { useState } from 'react';

interface DatasetUploadModalProps {
  workspaceId: string;
  onClose: () => void;
}

export const DatasetsActionUploadModal = ({ workspaceId, onClose }: DatasetUploadModalProps) => {
  const [isUploadStarted, setIsUploadStarted] = useState<boolean>(false);

  const { useAsyncNotification, addNotification } = useNotifications();

  const [uploadDatasetV2] = useUploadDatasetV2Mutation();
  const [uploadDatasetV2Finalizer] = useUploadDatasetV2FinalizerMutation();
  const uploadFile = useFileUpload();

  const uploadDatasetAsyncFunction = useAsyncNotification(
    'Dataset uploaded successfully.',
    async ({ description, file }: { description: string; file: File }) => {
      if (!file) {
        return;
      }
      const { name } = file;

      try {
        const { data } = await uploadDatasetV2({
          variables: {
            workspaceId,
            name,
            description,
            size: file.size,
          },
        });

        if (!data?.uploadDatasetV2?.urls) {
          return;
        }

        const etags = await uploadFile({
          file,
          urls: data?.uploadDatasetV2?.urls,
          partSize: data?.uploadDatasetV2?.partSize,
        });

        await uploadDatasetV2Finalizer({
          variables: {
            workspaceId,
            parts: etags,
            key: data?.uploadDatasetV2?.key,
            uploadId: data?.uploadDatasetV2?.uploadId,
          },
        });

        addNotification({
          text: 'The dataset will appear soon after the upload is complete.',
          type: 'info',
          duration: 10000,
        });
        onClose();
      } catch (error) {
        throw error;
      }
    },
    { loadingMessage: 'Uploading the dataset...' },
  );

  const submitDataset = async (formData: { description: string; file: File }) => {
    setIsUploadStarted(true);
    await uploadDatasetAsyncFunction(formData).then(() => {});
    setIsUploadStarted(false);
  };

  return (
    <Form initialValues={{ description: '', file: null }} onSubmit={submitDataset}>
      {({ handleSubmit, values }) => (
        <Stack>
          <FormFileUpload name="file" label="Dataset File" />
          <FormTextInput label="Description" name="description" multiline rows={3} />
          <Stack gap={4}>
            <AsyncButton
              disabled={!Boolean(values?.file) || isUploadStarted}
              fullWidth
              type="submit"
              data-cy="Datasets-Action-Upload-Modal-Upload-Button"
              onClick={handleSubmit}
            >
              Upload
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              onClick={onClose}
              data-cy="Datasets-Action-Upload-Modal-Cancel-Button"
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
