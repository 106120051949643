import { CopyIconButton } from '@components/button';
import { Checkbox } from '@components/form';
import { Typography } from '@components/typography';
import { Dataset } from '@generated/UseGraphqlHooks';
import { formatTime } from '@helper-functions/time';
import { TableCell } from '@mui/material';
import { MouseEvent, memo } from 'react';
import { DatasetsProviderType } from './DatasetsProvider';

type DatasetTableRowProps = {
  dataset: Pick<Dataset, 'datasetId' | 'name' | 'channel' | 'runs' | 'createdAt' | 'createdBy'>;
  context: Partial<DatasetsProviderType>;
};

export const DatasetsTableRow = memo(
  ({
    dataset: { datasetId, name, channel, runs, createdBy, createdAt },
    context: { selectedDatasets, setSelectedDatasets },
  }: DatasetTableRowProps) => {
    const handleDatasetCheckboxClick = (selectedDatasetId: string) => {
      return (event: MouseEvent) => {
        if (selectedDatasets.includes(selectedDatasetId)) {
          setSelectedDatasets(selectedDatasets.filter((id) => id !== selectedDatasetId));
        } else {
          setSelectedDatasets([...selectedDatasets, selectedDatasetId]);
        }
        event.preventDefault();
        event.stopPropagation();
      };
    };

    return (
      <>
        <TableCell width="30px">
          <Checkbox
            checked={selectedDatasets.includes(datasetId)}
            onClick={handleDatasetCheckboxClick(datasetId)}
          />
        </TableCell>
        <TableCell width="30px">
          <CopyIconButton value={datasetId} showValue />
        </TableCell>
        <TableCell width="250px">
          <Typography handleOverFlow variant="body2">
            {name}
          </Typography>
        </TableCell>
        <TableCell width="250px">
          <Typography handleOverFlow variant="body2">
            {channel}
          </Typography>
        </TableCell>
        <TableCell width="30px">
          <Typography handleOverFlow variant="body2">
            {runs}
          </Typography>
        </TableCell>
        <TableCell width="250px">
          <Typography handleOverFlow variant="body2">
            {createdBy}
          </Typography>
        </TableCell>
        <TableCell width="125px">
          <Typography variant="body2">{formatTime(createdAt, 'date') || '...'}</Typography>
        </TableCell>
      </>
    );
  },
);
