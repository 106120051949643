import { LoadingIcon } from '@assets/icons';
import { useModal } from '@components/modal';
import { useGetDatasetDetailsAnalyticsQuery } from '@generated/UseGraphqlHooks';
import { useTheme } from '@mui/material';
import {
  DatasetsDetailsAnalyticsCreateAnalyticsModal,
  DatasetsDetailsAnalyticsList,
} from '@subsets/workspaces';
import { useEffect } from 'react';
import { DatasetDetailsAccordion } from './DatasetDetailsAccordion';
import { RUNNING_STATUS } from './types';

type DatasetsDetailsAnalyticsProps = {
  workspaceId: string;
  datasetId: string;
};

export const DatasetsDetailsAnalytics = ({
  workspaceId,
  datasetId,
}: DatasetsDetailsAnalyticsProps) => {
  const { palette } = useTheme();

  const {
    data: getAnalyticsData,
    loading: analyticsLoading,
    refetch: refetchAnalytics,
    startPolling,
    stopPolling,
  } = useGetDatasetDetailsAnalyticsQuery({
    variables: { datasetId, workspaceId },
  });

  const analytics = getAnalyticsData?.getAnalytics || [];

  useEffect(() => {
    if (analytics.length === 0) {
      return;
    }
    if (analytics.some((analytic) => RUNNING_STATUS.includes(analytic.status))) {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [analytics]);

  const { showModal } = useModal({
    component: DatasetsDetailsAnalyticsCreateAnalyticsModal,
    componentProps: { workspaceId, datasetId, refetchAnalytics },
    modalProps: { title: 'Create Analytics' },
  });

  return (
    <DatasetDetailsAccordion
      title="Analytics"
      details={
        analyticsLoading ? (
          <LoadingIcon size={8} />
        ) : (
          <DatasetsDetailsAnalyticsList
            workspaceId={workspaceId}
            analytics={analytics}
            refetch={refetchAnalytics}
          />
        )
      }
      count={!analyticsLoading ? analytics.length : null}
      onCreate={showModal}
    />
  );
};
