import { MoreVerticalIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { useModal } from '@components/modal';
import { Graph } from '@generated/UseGraphqlHooks';
import { Box, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { GraphsListViewPreviewMenuDeleteConfigButton } from './GraphsListViewPreviewMenuDeleteConfigButton';
import { GraphsListViewPreviewMenuDownloadConfiguration } from './GraphsListViewPreviewMenuDownloadConfiguration';
import { GraphsListViewPreviewMenuDuplicateConfiguration } from './GraphsListViewPreviewMenuDuplicateConfiguration';
import { GraphsListViewPreviewMenuEditConfiguration } from './GraphsListViewPreviewMenuEditConfiguration';

interface GraphsListViewPreviewMenuProps {
  graph: Graph;
  refetch: () => void;
}

export const GraphsListViewPreviewMenu = ({ graph, refetch }: GraphsListViewPreviewMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: MouseEvent) => setAnchorEl(event.currentTarget as HTMLElement);

  const { showModal: showEditModal } = useModal({
    component: GraphsListViewPreviewMenuEditConfiguration,
    componentProps: { ...graph, refetch },
    modalProps: { title: `Edit ${graph?.name}` },
  });

  const { showModal: showDuplicateModal } = useModal({
    component: GraphsListViewPreviewMenuDuplicateConfiguration,
    componentProps: { ...graph, refetch },
    modalProps: { title: `Save ${graph?.name || 'graph'} copy as` },
  });

  const { showModal: showDownloadModal } = useModal({
    component: GraphsListViewPreviewMenuDownloadConfiguration,
    componentProps: { ...graph },
    modalProps: { title: `Download ${graph?.name}` },
  });

  const handleEditClick = () => {
    showEditModal();
    handleClose();
  };
  const handleDuplicateClick = () => {
    showDuplicateModal();
    handleClose();
  };

  const handleDownloadClick = () => {
    showDownloadModal();
    handleClose();
  };

  return (
    <Box>
      <IconButton onClick={handleClick} Icon={MoreVerticalIcon} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleDuplicateClick}>Duplicate</MenuItem>
        <MenuItem onClick={handleDownloadClick}>Download</MenuItem>
        <GraphsListViewPreviewMenuDeleteConfigButton
          graphId={graph.graphId}
          name={graph.name}
          workspaceId={graph.workspaceId}
          refetch={refetch}
          onClose={handleClose}
        />
      </Menu>
    </Box>
  );
};
