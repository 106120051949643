import { FileTextIcon } from '@assets/icons';
import { CopyIconButton, IconButton } from '@components/button';
import { ExternalLink } from '@components/link';
import { Typography } from '@components/typography';
import { formatTime } from '@helper-functions/time';
import { Box, TableCell } from '@mui/material';
import { ChannelTableRowDropdown } from './ChannelTableRowDropdown';

interface ChannelTableRowProps {
  name: string;
  channelId?: string;
  documentation?: boolean;
  organization?: string;
  organizationId?: string;
  selectedOrganizationId?: string;
  updatedAt?: string;
  description?: string;
}

export const ChannelTableRow = (props: ChannelTableRowProps) => {
  const { name, organization, description, documentation, updatedAt, channelId } = props;

  return (
    <>
      <TableCell>
        <CopyIconButton value={channelId} showValue />
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4}>
          {name || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4}>
          {description || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        {documentation && (
          <ExternalLink unstyled href={`/documentation?channelId=${channelId}`}>
            <IconButton Icon={FileTextIcon} />
          </ExternalLink>
        )}
      </TableCell>
      <TableCell>
        <Typography noWrap handleOverFlow variant="body2" pr={4}>
          {organization || '-'}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography noWrap handleOverFlow variant="body2">
            {formatTime(updatedAt, 'date') || '-'}
          </Typography>
          <Box pr={4}>
            <ChannelTableRowDropdown {...props} />
          </Box>
        </Box>
      </TableCell>
    </>
  );
};
