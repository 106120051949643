import { HelpCircleIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Typography } from '@components/typography';
import { Box, Collapse, Divider, Stack, useTheme } from '@mui/material';
import { useState } from 'react';

export const Shortcuts = () => {
  const [hoveredOver, setHoveredOver] = useState(false);
  const { palette } = useTheme();
  return (
    <Box position="absolute" top={16} right={16} display="flex" flexDirection="column">
      <IconButton
        onMouseEnter={() => setHoveredOver(true)}
        onMouseLeave={() => setHoveredOver(false)}
        size={24}
        Icon={HelpCircleIcon}
        sx={{
          alignSelf: 'flex-end',
          borderRadius: '20px',
          border: '1px solid transparent',
          '&:hover': {
            border: `1px solid ${palette.grey[500]}`,
          },
        }}
      />
      <Collapse in={hoveredOver}>
        <Stack
          mt={2}
          borderRadius="4px"
          sx={{
            border: `1px solid ${palette.grey[500]}`,
            backgroundColor: palette.background.paper,
            width: '300px',
          }}
          p={4}
          gap={1}
        >
          <Typography variant="subtitle1" textAlign="center">
            Keyboard Shortcuts
          </Typography>
          <Divider color={palette.text.primary} sx={{ my: 1 }} />
          <Box display="flex" justifyContent="space-between">
            <Typography>Pan Canvas</Typography>
            <Typography variant="body2">LMB + Drag</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography>Zoom Canvas</Typography>
            <Typography variant="body2">Mouse Wheel</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography>Select Intersection</Typography>
            <Typography variant="body2">Shift + Drag</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography>Select Multiple</Typography>
            <Typography variant="body2">Shift + LMB</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography>Delete Selection</Typography>
            <Typography variant="body2">Delete</Typography>
          </Box>
        </Stack>
      </Collapse>
    </Box>
  );
};
