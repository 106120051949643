import { AlertTriangleIcon, HourglassIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Tooltip } from '@components/tooltip';
import { useTheme } from '@mui/material';

interface DatasetsDetailsMicroserviceStatusProps {
  status: string;
  workspaceId: string;
  microServiceName: string;
  datasetId: string;
  microServiceId?: string;
}

export const DatasetsDetailsMicroserviceStatus = ({
  status,
  workspaceId,
  datasetId,
  microServiceId,
  microServiceName,
}: DatasetsDetailsMicroserviceStatusProps) => {
  const { palette } = useTheme();

  const lowerStatus = (status || '').toLocaleLowerCase();

  const url =
    microServiceName === 'analytics'
      ? `/workspaces/${workspaceId}/experiments/analytics/${microServiceId}/logs`
      : microServiceName === 'umap'
        ? `/workspaces/${workspaceId}/experiments/umap/${microServiceId}/logs`
        : microServiceId
          ? `/workspaces/${workspaceId}/libraries/${datasetId}/${microServiceName}/${microServiceId}`
          : `/workspaces/${workspaceId}/libraries/${datasetId}/${microServiceName}`;

  if (lowerStatus === 'failed' || lowerStatus === 'cancelled') {
    return (
      <Tooltip title="View logs" variant="secondary" disableInteractive>
        <IconButton
          Icon={AlertTriangleIcon}
          variant="error"
          size={16}
          onClick={(e) => {
            e.preventDefault();
            window.open(url, '_blank');
          }}
          style={{ cursor: 'pointer' }}
        />
      </Tooltip>
    );
  }
  if (
    lowerStatus !== 'success' &&
    lowerStatus !== 'completed' &&
    lowerStatus !== 'complete' &&
    lowerStatus !== 'failed' &&
    lowerStatus !== 'cancelled'
  ) {
    return <HourglassIcon size={16} style={{ marginLeft: '6px' }} />;
  }
  return <div />;
};
