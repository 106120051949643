import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

export type CheckboxProps = MuiCheckboxProps & {
  error?: string;
  label?: ReactNode;
  maintainGutters?: boolean;
};

export const Checkbox = ({ label, ...props }: CheckboxProps) => {
  const { palette } = useTheme();
  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          disableRipple
          checkedIcon={
            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M 11.834 5.128 C 12.04 5.313 12.056 5.629 11.872 5.835 L 7.372 10.835 C 7.275 10.942 7.135 11.003 6.99 11 C 6.845 10.997 6.709 10.932 6.616 10.82 L 4.116 7.82 C 3.939 7.608 3.968 7.293 4.18 7.116 C 4.392 6.939 4.707 6.968 4.884 7.18 L 7.015 9.736 L 11.128 5.166 C 11.313 4.96 11.629 4.944 11.834 5.128 Z"
                fill={palette.checkbox.unchecked.background}
                stroke={palette.checkbox.unchecked.background}
              />
            </svg>
          }
          {...props}
        />
      }
      sx={{ marginRight: label ? 4 : 0 }}
      label={label}
    />
  );
};
