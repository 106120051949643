import { AsyncButton, Button } from '@components/button';
import { Form, FormSelect } from '@components/form';
import { useCreateAnalyticsMutation, useGetAnalyticsTypesQuery } from '@generated/UseGraphqlHooks';
import { MenuItem, Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { FormikProps } from 'formik';
import { sortBy } from 'lodash';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  type: Yup.string().required('Analytics type is required'),
});

interface DatasetsDetailsAnalyticsCreateAnalyticsModalProps {
  workspaceId: string;
  datasetId: string;
  onClose: () => void;
  refetchAnalytics: () => void;
}

export const DatasetsDetailsAnalyticsCreateAnalyticsModal = ({
  onClose,
  workspaceId,
  datasetId,
  refetchAnalytics,
}: DatasetsDetailsAnalyticsCreateAnalyticsModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [createAnalytics] = useCreateAnalyticsMutation();
  const { data: analyticsData } = useGetAnalyticsTypesQuery();

  const sortedAnalyticsTypes = sortBy(analyticsData?.getAnalyticsTypes, (type) => type);

  const handleCreateAnalytics = useAsyncNotification(
    'Successfully created analytics.',
    async ({ type }: { type: string }) => {
      onClose();
      await createAnalytics({ variables: { datasetId, workspaceId, type } });
      void refetchAnalytics();
    },
  );

  return (
    <Form
      validateOnBlur={false}
      initialValues={{ type: '' }}
      validationSchema={validationSchema}
      onSubmit={handleCreateAnalytics}
    >
      {(properties: FormikProps<{ type: string }>) => {
        const { dirty, isValid } = properties;
        return (
          <Stack>
            <FormSelect label="Type" name="type">
              {(sortedAnalyticsTypes || []).map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </FormSelect>
            <Stack gap={4}>
              <AsyncButton
                data-cy="Analytics-Create-Modal-Create-Button"
                disabled={!(isValid && dirty)}
                fullWidth
                type="submit"
              >
                Create
              </AsyncButton>
              <Button
                data-cy="Analytics-Create-Modal-Cancel-Button"
                fullWidth
                variant="secondary"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        );
      }}
    </Form>
  );
};
