import { ChevronLeftIcon, ChevronRightIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Image } from '@components/image';
import { OptionsMenu } from '@components/menu';
import {
  useEditWorkspaceThumbnailMutation,
  useGetDatasetThumbnailsQuery,
} from '@generated/UseGraphqlHooks';
import { Stack, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { map } from 'lodash';
import { CSSProperties, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';

type WorkspaceRecentDatasetsImagesSliderProps = {
  loading?: boolean;
  workspaceId: string;
  datasetId: string;
};

const arrowStyles: CSSProperties = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  width: 30,
  height: 30,
  cursor: 'pointer',
};

export const WorkspaceRecentDatasetsImagesSlider = ({
  loading,
  workspaceId,
  datasetId,
}: WorkspaceRecentDatasetsImagesSliderProps) => {
  const { palette } = useTheme();
  const { data } = useGetDatasetThumbnailsQuery({ variables: { workspaceId, datasetId } });
  const { useAsyncNotification } = useNotifications();
  const [editWorkspaceThumbnail] = useEditWorkspaceThumbnailMutation();

  const handleSetThumbnail = useAsyncNotification(
    'You have successfully changed the workspace thumbnail',
    async (url: string) => {
      await editWorkspaceThumbnail({
        variables: {
          workspaceId,
          thumbnail: url,
        },
      });
    },
  );

  return (
    <Stack sx={{ height: '100%', width: '100%' }}>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <IconButton
              onClick={onClickHandler}
              tooltip={label}
              style={{ ...arrowStyles, left: 15 }}
              Icon={ChevronLeftIcon}
            />
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <IconButton
              onClick={onClickHandler}
              tooltip={label}
              style={{ ...arrowStyles, right: 15 }}
              Icon={ChevronRightIcon}
            />
          )
        }
      >
        {map(data?.getDatasetThumbnails, (url) => (
          <Fragment key={url}>
            <Image url={url} />
            <OptionsMenu
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
                borderRadius: '20px',
                backgroundColor: palette.background.default,
              }}
              items={[{ label: 'Set workspace thumbnail', onClick: () => handleSetThumbnail(url) }]}
              menuProps={{ transformOrigin: { vertical: 'top', horizontal: 'right' } }}
            />
          </Fragment>
        ))}
      </Carousel>
    </Stack>
  );
};
