import { PlusIcon } from '@assets/icons';
import { GridBox } from '@components/grid-box';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Stack, useTheme } from '@mui/material';
import { get, split } from 'lodash';
import { useLocation } from 'react-use';
import { GraphsAddGraphModal } from '../graphs';

export const WorkspaceRecentGraphsAddNewGraph = () => {
  const { palette } = useTheme();
  const { pathname } = useLocation();
  const workspaceId = get(split(pathname, '/', 3), '2');
  const { showModal } = useModal({
    component: GraphsAddGraphModal,
    componentProps: { workspaceId },
    modalProps: { title: 'New Graph' },
  });

  return (
    <GridBox
      sx={{
        height: '100%',
        width: '100%',
        '& .MuiCardContent-root': { height: '100%', width: '100%' },
      }}
      onClick={showModal}
      content={
        <Stack
          justifyContent="center"
          alignItems="center"
          height="100%"
          sx={{ backgroundColor: palette.background.default }}
          gap={1}
        >
          <PlusIcon size="30%" />
          <Typography variant="body1">New graph</Typography>
        </Stack>
      }
    />
  );
};
