import { PlusIcon } from '@assets/icons';
import { Button } from '@components/button';
import { Group } from '@components/layout';
import { useModal } from '@components/modal';
import { Tooltip } from '@components/tooltip';
import { useGetOrganizationSettingsQuery } from '@generated/UseGraphqlHooks';
import { Stack, useTheme } from '@mui/material';
import { OrganizationsMembersInviteMemberModal } from './OrganizationsMembersInviteMemberModal';
import { OrganizationsMembersTable } from './OrganizationsMembersTable';

const ORGANIZATION_MEMBERS_USAGE_MAX_LIMIT_TOOLTIP_TEXT =
  'You have reached your member account limit. To invite new members, remove or cancel current member, or Edit limit/upgrade';

type OrganizationMembersProps = {
  organizationId: string;
  organizationRole: string;
};

export const OrganizationsMembers = ({
  organizationId,
  organizationRole,
}: OrganizationMembersProps) => {
  const { palette } = useTheme();

  const { data: organizationSettingsData, loading: organizationSettingsLoading } =
    useGetOrganizationSettingsQuery({
      variables: { organizationId, setting: 'Members' },
    });

  const orgLimits = organizationSettingsData?.getOrganizationSettings?.[0];
  const { usage, setting } = orgLimits || { usage: '', setting: '' };
  const hasOrganizationMembersReachedLimit = Number(usage) === Number(setting);

  const { showModal } = useModal({
    component: OrganizationsMembersInviteMemberModal,
    componentProps: { organizationId, organizationRole },
    modalProps: { title: 'Invite a new organization member' },
  });

  return (
    <Stack sx={{ height: '100%', gap: 6, py: 8 }}>
      <Group justifyContent="flex-end" alignItems="center">
        <Tooltip
          title={
            hasOrganizationMembersReachedLimit
              ? ORGANIZATION_MEMBERS_USAGE_MAX_LIMIT_TOOLTIP_TEXT
              : ''
          }
          variant="secondary"
          placement="top"
        >
          <Button
            startIcon={PlusIcon}
            disabled={hasOrganizationMembersReachedLimit}
            onClick={showModal}
          >
            Invite member
          </Button>
        </Tooltip>
      </Group>
      <OrganizationsMembersTable
        organizationId={organizationId}
        organizationRole={organizationRole}
      />
    </Stack>
  );
};
