import { LoadingIcon } from '@assets/icons';
import { TableView, TableViewButton } from '@components/button';
import { useGraphqlPagination } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import { GetGraphsQuery, Graph, useGetGraphsQuery } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { GraphsGridView } from './GraphsGridView';
import { GraphsListView } from './GraphsListView';

interface GraphsWorkspaceGraphsProps {
  workspaceId: string;
}

export const GraphsWorkspaceGraphs = ({ workspaceId }: GraphsWorkspaceGraphsProps) => {
  const pagination = useGraphqlPagination<GetGraphsQuery, Graph>(
    useGetGraphsQuery({ variables: { limit: 30, staged: false, workspaceId } }),
    'getGraphs',
    'graphId',
  );

  const [tableView, setTableView] = useState<TableView>('grid');

  const isItemInListView = tableView === 'list';

  if (pagination.loading) {
    return (
      <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
        <LoadingIcon size={16} />
      </Stack>
    );
  }

  if (pagination.data.length === 0) {
    return (
      <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
        <Typography>You have no graphs.</Typography>
      </Stack>
    );
  }

  return (
    <Stack data-cy="Graphs-Workspace-Graphs" height="100%" pt={8} gap={8}>
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
        <TableViewButton mode={tableView} onChange={setTableView} />
      </Stack>
      {isItemInListView ? (
        <GraphsListView pagination={pagination} />
      ) : (
        <GraphsGridView pagination={pagination} />
      )}
    </Stack>
  );
};
