import { AsyncButton, Button } from '@components/button';
import { Form, FormFileUpload, FormTextInput } from '@components/form';
import { useFileUpload } from '@fileUpload/FileUpload';
import {
  useCreateManagedGanv2FinalizerMutation,
  useCreateManagedGanv2Mutation,
  useGetGanModelsQuery,
} from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { FormikProps } from 'formik';
import * as yup from 'yup';

interface NewGanModelModalProps {
  onClose: () => void;
  organizationId: string;
}

interface SubmitType {
  name: string;
  description: string;
  flags: string;
  file: File;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  description: yup.string(),
  flags: yup.string(),
  file: yup.mixed().required('File is required'),
});

export const NewGanModelModal = ({ onClose, organizationId }: NewGanModelModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [createManagedGANV2] = useCreateManagedGanv2Mutation();
  const [createManagedGANV2Finalizer] = useCreateManagedGanv2FinalizerMutation();
  const uploadFile = useFileUpload();
  const { refetch } = useGetGanModelsQuery({
    variables: {
      organizationId,
    },
  });

  const handleFormSubmit = useAsyncNotification(
    'Successfully created the new GAN Model',
    async ({ name, description, file, flags }: SubmitType) => {
      if (!file) {
        return;
      }

      try {
        const { data } = await createManagedGANV2({
          variables: { name, organizationId, description, flags, size: file.size },
        });

        if (!data?.createManagedGANV2?.urls) {
          return;
        }

        const etags = await uploadFile({
          file,
          urls: data?.createManagedGANV2?.urls,
          partSize: data?.createManagedGANV2?.partSize,
        });

        await createManagedGANV2Finalizer({
          variables: {
            organizationId,
            parts: etags,
            key: data?.createManagedGANV2?.key,
            uploadId: data?.createManagedGANV2?.uploadId,
          },
        });

        await refetch();
        onClose();
      } catch (error) {
        throw error;
      }
    },
  );

  return (
    <Form
      enableReinitialize
      initialValues={{
        name: '',
        description: '',
        flags: '',
        file: null,
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleFormSubmit}
    >
      {({ isValid, handleSubmit, dirty, isSubmitting }: FormikProps<{ file: string }>) => (
        <Stack>
          <FormTextInput name="name" label="Name" />
          <FormFileUpload name="file" label="File" />
          <FormTextInput name="flags" label="Flags" />
          <FormTextInput name="description" label="Description" multiline rows={3} />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              loading={isSubmitting}
              disabled={!(isValid && dirty && !isSubmitting)}
              onClick={handleSubmit}
            >
              Create
            </AsyncButton>
            <Button fullWidth variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
