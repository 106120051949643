import { PlusIcon } from '@assets/icons';
import { Button } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Stack } from '@mui/material';
import { NewChannelModal } from './NewChannelModal';

type ChannelHeaderProps = {
  name: string;
  usage: number;
  limit: string;
  organizationId: string;
};

export const ChannelHeader = ({ name, organizationId, usage, limit }: ChannelHeaderProps) => {
  const { showModal: showNewChannelModal } = useModal({
    component: NewChannelModal,
    componentProps: { organizationId },
    modalProps: { title: 'New Channel' },
  });

  const isChannelsReachedLimit = Number(usage) === Number(limit);

  return (
    <Stack spacing="2px">
      <Typography variant="caption2">{name}</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Channels</Typography>
        <Button
          startIcon={PlusIcon}
          onClick={showNewChannelModal}
          disabled={isChannelsReachedLimit}
        >
          New channel
        </Button>
      </Stack>
    </Stack>
  );
};
